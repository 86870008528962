@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'roboto', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
.comp-root{
  overflow-x: hidden;
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

//主体内容部分宽度
// .container {
//   box-sizing: content-box;
//   width: 100%;
//   @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
//   @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

// }

//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}


@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}