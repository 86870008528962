@import "../../assets/styles/utils";

.header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @include res(display,flex);
    @include res(top,55px,10 / 55);
    @include res(padding-left,65px, 10 / 65);
    @include res(padding-right,120px, 70 / 120);
    &.nav-hidden{
        .right-nav{
            opacity: 0;
            visibility: hidden;
        }
    }
    .left-logo{
        display: flex;
        align-items: center;
        @include res(height,51px,(lg:46px,mmd:44px,md:42px,sm:38px));
        img{
            display: block;
            width: auto;
            @include res(margin-top,0,(md:5px));
            @include res(height,38px,(md:32px,sm:28px));
        }
    }
    .right-nav{
        align-items: center;
        justify-content: space-between;
        transition: all .3s;
        @include res(height,51px,(lg:46px,mmd:44px,md:42px,sm:38px));
        @include res(display,flex,(sm:none));
        ul{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            li{
                position: relative;
                transition: all .3s;
                @include res(font-size,18px, 14 / 18);
                @include res(margin-right,40px, 10 / 40);
                &::after{
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: #91b122;
                    position: absolute;
                    border-radius: 50%;
                    opacity: 0;
                    @include res(left,calc(50% - 3px));
                    @include res(bottom,-15px, -10 / -15);
                }
                &:hover{
                    color: $color-main;
                }
                &.active{
                    &::after{
                        opacity: 1;
                    }
                }
            }
        }
        .language{
            border: 1px solid #6b6e6a;
            @include res(padding-top,15px, 10 / 15);
            @include res(padding-bottom,15px, 10 / 15);
            @include res(padding-left,25px, 15 / 25);
            @include res(padding-right,25px, 15 / 25);
        }
    }
}
.open-menu{
    cursor: pointer;
    position: fixed;
    z-index: 102;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    @include res(height,51px,(lg:46px,mmd:44px,md:42px,sm:38px));
    @include res(padding-left,11px, (sm:8px));
    @include res(padding-right,11px,(sm:8px));
    @include res(top,55px,10 / 55);
    @include res(right,60px,10 / 60);
    &.active{
        border: 1px solid #fff;
        .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
            background-color: #fff;
        }
    }
}
.mob-menu{
    position: fixed;
    top: 0;
    left: 100%;
    height: 100vh;
    background-color: $color-main;
    transition: all .3s;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 101;
    @include res(padding-top,70px,(lg:60px,mmd:50px,md:40px,sm:30px,xs:25px));
    @include res(padding-left,70px, 50 / 70);
    @include res(padding-right,30px, 10 / 30);
    @include res(padding-bottom,30px, 10 / 30);
    @include res(max-width,460px);
    &.active{
        transform: translateX(-100%);
    }
    .language{
        display: block;
        color: rgba(255,255,255,.8);
        @include res(font-size,26px, 16 / 26);
        @include res(margin-bottom,100px, 50 / 100);
    }
    ul{
        li{
            transition: all .3s;
            color: rgba(255,255,255,.8);
            @include res(margin-bottom,35px,15 / 35);
            @include res(font-size,38px, 18 / 38);
            &:hover{
                padding-left: 10px;
                color: #fff;
            }
        }
    }
}