@import "../../assets/styles/utils";

.footer{
    background-color: #dce1db;
    position: relative;
    align-items: stretch;
    justify-content: center;
    @include res(display,flex,(xs:block));
    @include res(padding-top,80px, 60 / 80);
    @include res(padding-bottom,50px, 10 / 50);
    @include res(padding-left,30px, 15 / 30);
    @include res(padding-right,30px, 15 / 30);
    @include res(margin-top,130px, 50 / 130);
    .left,.right{
        flex-shrink: 0;
        box-sizing: border-box;
        @include res(width,385px,(xs:100%));
        @include res(line-height,2.25, 1.6 / 2.25);
        h1{
            font-weight: bold;
            line-height: 1;
            @include res(font-size,32px, 18 / 32);
            @include res(margin-top,15px, 5 / 15);
            @include res(margin-bottom,35px, 15 / 35);
        }
        h2{
            color: $color-main;
            @include res(font-size,18px, 16 / 18);
        }
        .contact{
            display: inline-block;
            border: 1px solid $color-main;
            border-radius: 20px;
            color: $color-main;
            transition: all .3s;
            @include res(padding-left,30px, 15 / 30);
            @include res(padding-right,30px, 15 / 30);
            @include res(padding-top,1px, 5 / 1);
            @include res(padding-bottom,1px, 5 / 1);
            @include res(margin-top,10px);
            &:hover{
                background-color: $color-main;
                color: #fff;
            }
        }
    }
    .left{
        @include res(padding-right,50px,(sm:30px,xs:0));
        @include res(text-align,right,(xs:left));
        @include res(border-right,1px solid #8baa89,(xs:none));
        @include res(border-bottom,none,(xs:1px solid #8baa89));
        @include res(padding-bottom,0,(xs:15px));
        @include res(margin-bottom,0,(xs:15px));
    }
    .right{
        @include res(padding-bottom,10px);
        @include res(padding-left,50px,(sm:30px,xs:0));
    }
    .back-top{
        border-radius: 50%;
        position: absolute;
        background: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        top: 0;
        left: 50%;
        cursor: pointer;
        transform: translate3d(-50%,-50%,0);
        transition: all .3s;
        @include res(width,56px, 40 / 56);
        @include res(height,56px, 40 / 56);
        &:hover{
            margin-top: -5px;
        }
        .iconfont{
            @include res(font-size,22px, 18 / 22);
        }
        span{
            color: #000;
            display: block;
            width: 100%;
            text-align: center;
            position: absolute;
            left: 0;
            top: 110%;
            text-transform: uppercase;
        }
    }
}
.copyright{
    color: #fff;
    background-color: $color-main;
    text-align: center;
    @include res(padding-top,25px, 10 / 25);
    @include res(padding-bottom,25px, 10 / 25);
}