.jason-map {
  box-sizing: border-box;
  position: fixed;
  right: -400px;
  top: 0;
  height: 100vh;
  padding: 10px 45px;
  background-color: #333333;
  line-height: 1.8;
  color: #bfbfbf;
  z-index: 10086;
  width: 400px;
  font-size: 14px;
  transition: transform 0.5s;
}

.jason-map * {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #bfbfbf;
  text-decoration: none;
}

.jason-map li {
  display: inline-block;
}

.jason-map:before, .jason-map:after, .jason-map-content {
  display: inline-block;
  vertical-align: middle;
}

.jason-map:before, .jason-map:after {
  content: "";
  height: 100%;
}

.jason-map-content {
  max-width: 95%;
}

.jason-map-heading {
  color: #ddd0b5;
  font-size: 24px;
  margin-bottom: 25px;
}

.jason-map-title {
  font-weight: bold;
  position: relative;
}

.jason-map-title:before {
  content: "";
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: #ddd0b5;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  right: 100%;
  left: auto;
  margin: auto;
  transform: translateX(-10px);
}

.jason-map-item + .jason-map-item {
  margin-top: 15px;
}

.jason-map-item li span {
  display: inline-block;
  vertical-align: middle;
}

.jason-map-item li:not(:last-child):after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
  width: 2px;
  background-color: #A1A1A1;
  height: 15px;
}

.jason-map-item a {
  display: inline-block;
}

.jason-map-item a:hover {
  opacity: 0.7;
}

.jason-map-footer {
  margin-top: 25px;
  margin-left: -15px;
  padding-left: 15px;
  border-left: 1px solid #ddd0b5;
}

.jason-map-footer h1, .jason-map-footer h2, .jason-map-footer h3, .jason-map-footer h4, .jason-map-footer h5, .jason-map-footer h6 {
  font-weight: bold;
  color: #fff;
}

.jason-map-active {
  transform: translate3d(-400px, 0, 0);
}

.jason-map-footer p {
  text-indent: 0;
}
