@font-face {
  font-family: "iconfont"; /* Project id 2973384 */
  src: url('iconfont.woff2?t=1638260497558') format('woff2'),
       url('iconfont.woff?t=1638260497558') format('woff'),
       url('iconfont.ttf?t=1638260497558') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-duihao:before {
  content: "\eaf1";
}

.icon-jiantou_you:before {
  content: "\eb0c";
}

.icon-jiantou_shang:before {
  content: "\eb0d";
}

.icon-jiantou_zuo:before {
  content: "\eb0e";
}

